import styled from "styled-components";
import { getThemeColor } from "../../../global-theme";

const StyledSideMenu = styled.div`
  a {
    font-weight: 500;
    font-size: 14px;
    font-family: "Inter";
  }
  .MuiButtonBase-root,
  .MuiMenuItem-root,
  .MuiListItemIcon-root {
    color: ${getThemeColor("dark")} !important;
    &:hover {
      background-color: ${getThemeColor("white")};
    }
  }

  .RaMenuItemLink-active {
    color: ${getThemeColor("dark")}!important;
    background-color: ${getThemeColor("white")};
  }
`;

export default StyledSideMenu;
