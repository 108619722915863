import {
  Edit,
  ImageField,
  ImageInput,
  SimpleForm,
  TextInput,
  useDataProvider,
  useGetRecordId,
  useNotify,
} from 'react-admin';
import { RESTAURANT_RESOURCE } from '../../../constants';
import { useNavigate } from 'react-router-dom';
import { StyledRestaurantEdit } from './StyledRestaurantEdit';
import { useState } from 'react';

import AddressInput from '../../../shared/AddressInput/AddressInput';
import { FieldValues } from 'react-hook-form';

const RestaurantsEdit = () => {
  const dataProvider = useDataProvider();
  const id = useGetRecordId();
  const notify = useNotify();
  const navigate = useNavigate();

  const [restaurantCoordinates, setRestaurantCoordinates] = useState<{
    lat: number;
    lng: number;
    zipCode?: string;
  }>({ lat: 0, lng: 0, zipCode: '' });

  const submitForm = async (data: FieldValues) => {
    const formData = new FormData();

    let requestBody = { ...data };
    delete requestBody.images;

    data.images.forEach((image: { rawFile: File; src: string }) => {
      if (image?.rawFile) {
        formData.append('images', image.rawFile);
      } else if (image.src) {
        formData.append('images', image.src);
      }
    });

    for (const key in requestBody) {
      formData.append(key, requestBody[key as keyof typeof requestBody]);
    }

    if (
      restaurantCoordinates.lat &&
      restaurantCoordinates.lng &&
      restaurantCoordinates.zipCode
    ) {
      formData.delete('zipCode');
      formData.delete('location');
      formData.append('lat', restaurantCoordinates.lat.toString());
      formData.append('lng', restaurantCoordinates.lng.toString());
      formData.append('zipCode', restaurantCoordinates.zipCode);
    }

    dataProvider
      .update(RESTAURANT_RESOURCE, {
        id,
        data: formData,
        previousData: await dataProvider.getOne(RESTAURANT_RESOURCE, {
          id,
        }),
      })
      .then(() => {
        navigate(`/${RESTAURANT_RESOURCE}`);
        notify('Restaurant updated successfully', { type: 'success' });
      })
      .catch(error => {
        notify('Could not update restaurant. Please try again', {
          type: 'error',
        });
      });
  };

  return (
    <StyledRestaurantEdit>
      <h1 className="title">Edit restaurant</h1>
      <Edit>
        <SimpleForm onSubmit={submitForm}>
          <div className="restaurant-edit-container">
            <div className="column-1">
              <label>Restaurant name</label>
              <TextInput source="name" required />
              <label>Restaurant name</label>
              <TextInput source="phoneNumber" required />
              <label>Restaurant address</label>
              <AddressInput
                source={'address'}
                addressChangeCallback={setRestaurantCoordinates}
                isRequired={true}
                label="Restaurant address"
              />
              <label>Restaurant address</label>
              <TextInput source="description" required />
            </div>
            <div className="column-2">
              <label>Restaurant images</label>
              <ImageInput source="images" multiple={true} isRequired={true}>
                <ImageField source="src" title="title" />
              </ImageInput>
            </div>
          </div>
        </SimpleForm>
      </Edit>
    </StyledRestaurantEdit>
  );
};

export default RestaurantsEdit;
