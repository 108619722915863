import React, { Dispatch } from "react";
import { layoutReducer } from "./layout.reducer";

export type GlobalContextType = {
  pageTitle: string;
};

export type ActionType = {
  type: string;
  payload: any;
};

export const DEFAULT_GLOBAL_CONTEXT: GlobalContextType = {
  pageTitle: "",
};

export const GlobalContext = React.createContext<{
  state: GlobalContextType;
  dispatch: Dispatch<ActionType>;
}>({
  state: DEFAULT_GLOBAL_CONTEXT,
  dispatch: () => {},
});

const reducers = {
  layout: layoutReducer,
};

export function globalReducer(
  state: GlobalContextType,
  action: ActionType
): GlobalContextType {
  type ReducersType = keyof typeof reducers;

  return Object.keys(reducers)
    .map((value: string) => {
      return {
        value: reducers[value as ReducersType](state, action),
        sectionPath: value,
      };
    })
    .reduce((globalState, currentState) => ({
      ...globalState,
      [currentState.sectionPath]: currentState.value,
    })).value;
}

export const useGlobalContext = (
  sectionPath: keyof GlobalContextType | undefined = undefined
) => {
  const context = React.useContext(GlobalContext);

  if (!context) {
    throw new Error(
      "useContext hook needs to be called inside the global context provider"
    );
  }

  const returnedState = sectionPath
    ? context.state[sectionPath]
    : context.state;

  return {
    dispatch: context.dispatch,
    state: returnedState,
  };
};
