import styled from 'styled-components';

export const StyledRestaurantEdit = styled.div`
  padding: 20px;

  .title {
    margin: 0;
  }

  div {
    display: block;
  }

  .MuiPaper-root {
    border-radius: 10px;
  }

  .restaurant-edit-container {
    display: flex;
    width: 100%;
    gap: 20px;

    .column-1 {
      flex: 1;
      width: 100%;
      border: 1px solid #dadada;
      padding: 15px;
      border-radius: 10px;

      input {
        width: 90%;
      }
    }

    .column-2 {
      flex: 1;
      width: 100%;
      border: 1px solid #dadada;
      padding: 15px;
      border-radius: 10px;
    }
  }
`;
