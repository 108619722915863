import React from "react";
import { Layout, LayoutProps } from "react-admin";
import SideMenu from "./SideMenu/SideMenu";
import AppBar from "./AppBar/AppBar";

const AdminLayout = (props: LayoutProps) => {
  return <Layout {...props} menu={SideMenu} appBar={AppBar} />;
};

export default AdminLayout;
