import styled from "styled-components";

export const StyledBagEdit = styled.div`
  padding: 20px;

  .title {
    margin: 0;
  }

  .MuiPaper-root {
    border-radius: 10px;
  }
  .fields-container {
    display: flex;
    width: 100%;
    gap: 20px;

    .informations {
      flex: 1;
      width: 100%;
      display: flex;
      flex-direction: column;
      border: 1px solid #dadada;
      padding: 15px;
      border-radius: 10px;
    }

    .availabilty {
      display: flex;
      flex-direction: column;
      width: 100%;
      flex: 1;
      border: 1px solid #dadada;
      padding: 15px;
      border-radius: 10px;
    }
  }
  .images {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    border: 1px solid #dadada;
    padding: 15px;
    border-radius: 10px;
  }
`;
