import { apiSlice } from '@/api/api';
import { Bag } from '@/types/bags';

export const bagOrders = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getBulkBags: builder.query<Bag[], string[]>({
      query: (ids: string[]) => `/api/bags/bulk?ids=${ids.join(',')}`,
      providesTags: (result, error, ids) =>
        ids.map(id => ({ type: 'Bag' as never, id })),
    }),
  }),
});

export const { useGetBulkBagsQuery } = bagOrders;
export default bagOrders.reducer;
