import React, { useState } from 'react';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useGetUsersQuery } from '../../../redux/analytics.slice';
import {
  CartesianGrid,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
  Line,
  ResponsiveContainer,
} from 'recharts';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import { handleDateChange } from '../utils/handleDateChange';

function UsersChart() {
  const [startDate, setStartDate] = useState(
    dayjs(new Date()).subtract(1, 'month'),
  );
  const [endDate, setEndDate] = useState(dayjs(new Date()));

  const users = useGetUsersQuery({
    startDate: startDate.toDate().toISOString(),
    endDate: endDate.toDate().toISOString(),
  });

  if (users.status !== QueryStatus.fulfilled) {
    return null;
  }

  return (
    <div className="card">
      <h2 className="title">New Users Chart</h2>
      <div className="pickers">
        <DatePicker
          label="Start date"
          value={startDate}
          onChange={newDate => handleDateChange(setStartDate, newDate)}
        />
        <DatePicker
          label="End date"
          value={endDate}
          onChange={newDate => handleDateChange(setEndDate, newDate)}
        />
      </div>
      <ResponsiveContainer width={'100%'} height={400}>
        <LineChart width={550} height={400} data={users.currentData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={'date'} />
          <YAxis dataKey={'count'} />
          <Tooltip />
          <Line
            type="monotone"
            dataKey={'count'}
            stroke="#44b762"
            strokeWidth={3}
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export default UsersChart;
