// in src/Dashboard.js
import * as React from 'react';
import Paper from '@mui/material/Paper';
import { Title, usePermissions } from 'react-admin';
import { StyledDashboard } from './StyledDashboard';

import UsersChart from './charts/UsersChart';
import { Grid } from '@mui/material';
import OrdersChart from './charts/OrdersChart';
import MoneyChart from './charts/MoneyChart';

const Dashboard = () => {
  const { permissions } = usePermissions();
  return (
    <StyledDashboard>
      <h1 className="title">DASHBOARD</h1>
      <Grid
        container
        spacing={3}
        alignContent={'center'}
        justifyContent={'flex-start'}
        width={'100%'}>
        {permissions === 'ADMIN' && (
          <Grid item sm={12} lg={6}>
            <Paper className="container">
              <UsersChart />
            </Paper>
          </Grid>
        )}
        <Grid item sm={12} lg={6}>
          <Paper className="container">
            <OrdersChart />
          </Paper>
        </Grid>
        <Grid item sm={12} lg={6}>
          <Paper className="container">
            <MoneyChart />
          </Paper>
        </Grid>
      </Grid>
    </StyledDashboard>
  );
};

export default Dashboard;
