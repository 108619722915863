import styled from "styled-components";

export const StyledOrdersList = styled.div`
  height: 100%;
  width: 100%;
  padding: 20px;

  .title {
    margin: 0;
  }

  .orders-container {
    height: 100%;
    width: 100%;
    background-color: white;

    .MuiDataGrid-root {
      padding: 10px;
    }

    .MuiDataGrid-columnHeader {
      background-color: white;
    }

    .paid {
      background-color: #e6fefb;
      color: #15c2c3;
      padding: 5px 15px;
      border-radius: 5px;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 1.2px;
    }

    .picked-up {
      background-color: #ecffd8;
      color: #52c424;
      padding: 5px 15px;
      border-radius: 5px;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 1.2px;
    }

    .refunded {
      background-color: #fff0f0;
      color: #ff4f51;
      padding: 5px 15px;
      border-radius: 5px;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 1.2px;
    }
  }
`;
