import { useGetBulkBagsQuery } from '@/redux/bags-orders';
import { Dialog, DialogContent, DialogTitle } from '@/shared/ui/dialog';
import { Order } from '@/types/orders';
import React from 'react';

type OrderDetailsDialogProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  order: Order | null;
};

const OrderDetailsDialog = ({
  open,
  setOpen,
  order,
}: OrderDetailsDialogProps) => {
  if (!order) return null;

  const bags = useGetBulkBagsQuery(order.cart.map(item => item._id));

  const bagsToShow = bags.data?.map(item => ({
    ...item,
    number: order.cart.find(
      cartItem => cartItem._id.toString() === item._id?.toString(),
    )?.number,
  }));

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent>
        <DialogTitle>Order details</DialogTitle>
        <div className="grid gap-4 grid-flow-row-dense grid-cols-3">
          {/* @ts-ignore */}
          {bagsToShow?.map(item => (
            <div
              key={item.id}
              className={'shadow-md rounded-md border border-gray-200 p-4'}>
              <h1 className={'text-lg font-bold'}>{item.name}</h1>
              <div>Quantity: {item.number}</div>
              <div>
                <img src={item.images?.[0]!} alt={item.name} />
              </div>
            </div>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default OrderDetailsDialog;
