import { apiSlice } from '@/api/api';
import { getSocket } from '@/services/websocket.sevice';
import { Order } from '@/types/orders';

const NEW_ORDER_EVENT = 'newOrder';

export const ordersSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getOrdersList: builder.query<Order[], string | undefined>({
      query: (bagId: string | undefined) =>
        bagId ? `/api/orders/bag/${bagId}` : '/api/orders',
      providesTags: (_result, _error, bagId) => {
        return [{ type: 'Order' as never, id: bagId || 'LIST' }];
      },
      async onCacheEntryAdded(
        _id: string | undefined,
        { updateCachedData, cacheDataLoaded },
      ) {
        const socket = getSocket();

        try {
          await cacheDataLoaded;

          socket.on(NEW_ORDER_EVENT, (socketData: Order) => {
            updateCachedData(oldData => {
              if (oldData) {
                return [...oldData, socketData];
              }
              return [socketData];
            });
          });
        } catch (error) {
          await cacheDataLoaded;
          socket.off();
        }
      },
    }),
    updateOrder: builder.mutation<
      Order,
      Partial<Order> & { bagId: string | undefined }
    >({
      query: ({ _id, ...data }) => ({
        url: `/api/orders/${_id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (_result, _error, { _id, bagId }) => [
        { type: 'Order' as never, id: bagId ? bagId : 'LIST' },
      ],
    }),
  }),
});

export const { useGetOrdersListQuery, useUpdateOrderMutation } = ordersSlice;
