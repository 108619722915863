import {
  Create,
  DateTimeInput,
  ImageField,
  ImageInput,
  SimpleForm,
  TextInput,
  useDataProvider,
  useNotify,
  required,
  RadioButtonGroupInput,
} from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { BAGS_RESOURCE } from '../../../constants';
import { StyledBagEdit } from '../edit/StyledBagEdit';
import { FieldValues } from 'react-hook-form';

const BagsCreate = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const navigate = useNavigate();

  const submitForm = async (data: FieldValues) => {
    const formData = new FormData();

    let requestBody = {
      ...data,
    };

    delete requestBody.images;
    delete requestBody.availability;
    delete requestBody.category;
    delete requestBody.from;
    delete requestBody.to;
    type RequestBodyKey = keyof typeof requestBody;
    data.images.forEach((image: { rawFile: File; src: string }) => {
      if (image?.rawFile) {
        formData.append('images', image.rawFile);
      } else if (image.src) {
        formData.append('images', image.src);
      }
    });

    formData.append('availability', data.from);
    formData.append('availability', data.to);
    formData.append('category', data.category);
    for (const key in requestBody) {
      formData.append(key, requestBody[key as RequestBodyKey]);
    }
    dataProvider
      .create(BAGS_RESOURCE, {
        data: formData,
      })
      .then(() => {
        navigate(`/${BAGS_RESOURCE}`);
        notify('Bag updated successfully', { type: 'success' });
      })
      .catch(error => {
        notify('Could not update bag. Please try again', {
          type: 'error',
        });
        console.error(error);
      });
  };

  return (
    <StyledBagEdit>
      <h1 className="title">Create new bag</h1>
      <Create>
        <SimpleForm onSubmit={submitForm}>
          <div className="fields-container">
            <div className="informations">
              <h2>General information</h2>
              <label>Bag name</label>
              <TextInput source="name" required />
              <label>Bag description</label>
              <TextInput source="description" multiline />
              <label>Bag price</label>
              <TextInput source="price" required />
              <label>Allergens</label>
              <TextInput source="allergens" multiline />
              <label title="How many bags are available">Stock</label>
              <TextInput source="stock" multiline />
              <label>Select bag category</label>
              <RadioButtonGroupInput
                label="Category"
                source="category"
                required
                choices={[
                  { id: '1lbs', name: '1lbs' },
                  { id: '3lbs', name: '3lbs' },
                  { id: '5lbs', name: '5lbs' },
                ]}
              />
            </div>

            <div className="availabilty">
              <h2>Availability</h2>
              <DateTimeInput
                source="from"
                label="Start availability time"
                required
              />
              <DateTimeInput
                source="to"
                label="End availability time"
                required
              />
            </div>
          </div>
          <div className="images">
            <ImageInput
              source="images"
              label="Bag images"
              multiple={true}
              isRequired={true}>
              <ImageField source="src" title="name" />
            </ImageInput>
          </div>
        </SimpleForm>
      </Create>
    </StyledBagEdit>
  );
};

export default BagsCreate;
