import io, { Socket } from "socket.io-client";
import UserService from "./user.service";

let socket: Socket | null = null;

export function getSocket() {
  if (!socket) {
    socket = io(process.env.REACT_APP_WS_URL || "http://localhost:8080", {
      auth: {
        token: UserService.getUser(),
      },
    });
    socket.on("connect_error", (error) => {
      console.error(error.message);
    });
  }
  return socket;
}

export function disconnectSocket() {
  socket?.disconnect();
  socket = null;
}
