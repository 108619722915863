import React, { useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';
import { useInput, InputProps } from 'react-admin';
import { StyledAddressInput } from './StyledAddressInput';

type AddressInputProps = InputProps & {
  addressChangeCallback?: (coordinates: {
    lat: number;
    lng: number;
    zipCode?: string;
  }) => void;
};

// Address input specially made for react admin
function AddressInput({
  addressChangeCallback = ({ lat, lng }) => {},
  ...props
}: AddressInputProps) {
  const { onChange, onBlur, ...rest } = props;
  const {
    field,
    fieldState: { isTouched, invalid, error },
    formState: { isSubmitted },
    isRequired,
  } = useInput({
    // Pass the event handlers to the hook but not the component as the field property already has them.
    // useInput will call the provided onChange and onBlur in addition to the default needed by react-hook-form.
    onChange,
    onBlur,
    ...props,
  });

  const autoCompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    (async () => {
      //@ts-ignore
      const { Autocomplete } = await google.maps.importLibrary('places');

      if (inputRef && inputRef.current) {
        autoCompleteRef.current = new Autocomplete(inputRef.current);
        autoCompleteRef.current?.addListener('place_changed', async () => {
          if (autoCompleteRef && autoCompleteRef.current) {
            const place = autoCompleteRef.current.getPlace();

            const zipCode = place.address_components?.find(component =>
              component.types.includes('postal_code'),
            )?.long_name;

            const latitude = place.geometry?.location?.lat() || 0;
            const longitude = place.geometry?.location?.lng() || 0;

            addressChangeCallback({ lat: latitude, lng: longitude, zipCode });
            if (inputRef && inputRef.current) {
              field.onChange({
                target: {
                  value: place.formatted_address,
                },
              });
            }
          }
        });
      }
    })();
  }, [inputRef]);

  return (
    <StyledAddressInput>
      <TextField
        inputRef={inputRef}
        {...field}
        label={props.label}
        fullWidth
        error={(isTouched || isSubmitted) && invalid}
        helperText={
          (isTouched || isSubmitted) && invalid && error ? error.message : ''
        }
        required={isRequired}
        {...rest}
      />
    </StyledAddressInput>
  );
}

export default AddressInput;
