import { ActionType, GlobalContextType } from "./global.context";

interface LayoutAction extends ActionType {
  payload: string;
}

export const layoutReducer = (
  state: GlobalContextType,
  action: LayoutAction
) => {
  switch (action.type) {
    case "CHANGE_TITLE":
      return {
        ...state,
        pageTitle: action.payload,
      };
  }
  return state;
};
