import React from "react";

export default function CheckoutView() {
  return (
    <>
      <form style={{ display: "block" }}>
        <input type="text" id="first_name" placeholder="first_name" />
        <br />
        <input type="text" id="last_name" placeholder="last_name" />
        <br />
        <input type="text" id="address" placeholder="address" />
        <br />
        <input type="text" id="ccnumber" placeholder="ccnumber" />
        <br />
        <input type="text" id="ccexp" placeholder="ccexp" />
        <br />
        <input type="text" id="cvv" placeholder="cvv" />
        <br />
        <input type="submit" id="payButton" />
      </form>
    </>
  );
}
