import React, { FC, ReactNode } from "react";
import {
  DEFAULT_GLOBAL_CONTEXT,
  GlobalContext,
  globalReducer,
} from "./global.context";

interface GlobalContextProps {
  children: ReactNode;
}

const GlobalContextProvider: FC<GlobalContextProps> = ({ children }) => {
  const [state, dispatch] = React.useReducer(
    globalReducer,
    DEFAULT_GLOBAL_CONTEXT
  );

  const contextValue = { state, dispatch };

  return (
    <GlobalContext.Provider value={contextValue}>
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
