import styled from "styled-components";

export const StyledAppBar = styled.div`
  .title {
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1.4px;
    font-family: "Inter";
    flex: 1;
  }
`;
