import { Menu } from 'react-admin';
import {
  BAGS_RESOURCE,
  DASHBOARD_RESOURCE,
  ORDERS_RESOURCE,
  RESTAURANT_RESOURCE,
  SUPPORT_RESOURCE,
  USERS_RESOURCE,
} from '../../../constants';
import StyledSideMenu from './StyledSideMenu';
import { Book, Dashboard, Person, Restaurant, Shop } from '@mui/icons-material';

const SideMenu = () => (
  <StyledSideMenu>
    <Menu>
      <Menu.Item
        to={`${DASHBOARD_RESOURCE}`}
        primaryText="Dashboard"
        leftIcon={<Dashboard />}
      />
      <Menu.Item
        to={`${RESTAURANT_RESOURCE}`}
        primaryText="Restaurants"
        leftIcon={<Restaurant />}
      />
      <Menu.Item
        to={`${BAGS_RESOURCE}`}
        primaryText="Bags"
        leftIcon={<Shop />}
      />
      <Menu.Item
        to={`${ORDERS_RESOURCE}`}
        primaryText="Orders"
        leftIcon={<Book />}
      />
      {/* <Menu.Item
        to={`${SUPPORT_RESOURCE}`}
        primaryText="Support"
        leftIcon={<Chat />}
      /> */}
      <Menu.Item
        to={`/${USERS_RESOURCE}`}
        leftIcon={<Person />}
        primaryText="Users"
      />
    </Menu>
  </StyledSideMenu>
);

export default SideMenu;
