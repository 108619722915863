import dayjs from 'dayjs';

export const handleDateChange = (
  setDate: (newDate: dayjs.Dayjs) => void,
  newDate: dayjs.Dayjs | null,
) => {
  if (!newDate) {
    setDate(dayjs(new Date()));
  } else {
    setDate(newDate);
  }
};
