import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { DataGrid, GridValueGetterParams } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import type { Order } from '../../types/orders';
import { StyledOrdersList } from './StyledOrdersList';
import { useGlobalContext } from '../../context/global.context';
import { User } from '../../types/user';
import { Eye } from 'lucide-react';
import OrderDetailsDialog from './OrderDetailsDialog';
import {
  useGetOrdersListQuery,
  useUpdateOrderMutation,
} from '@/redux/orders-slice';

export default function OrdersList() {
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);

  const { bagId } = useParams();

  const ordersData = useGetOrdersListQuery(bagId); // Pass the required arguments here

  const { dispatch } = useGlobalContext('pageTitle');

  const [updateOrderMutation] = useUpdateOrderMutation();

  useEffect(() => {
    dispatch({ type: 'CHANGE_TITLE', payload: 'Orders' });
  }, []);

  const markOrderAsRead = async (order: Order) => {
    if (order.opened) return;

    await updateOrderMutation({
      ...order,
      opened: true,
      bagId,
    });
  };

  const updateOrder = async (order: Order) => {
    await updateOrderMutation({ ...order, bagId });
  };

  const columns = [
    {
      field: 'userData',
      renderCell: ({ row }: { row: Order }) => {
        return (
          <div className="flex gap-2 items-center">
            <p>{row.userData.name}</p>
            {!row.opened && (
              <div className="bg-red-500 text-white rounded-md italic px-2">
                New order!
              </div>
            )}
          </div>
        );
      },
      headerName: 'User name',
      width: 300,
    },
    {
      field: 'userEmail',
      headerName: 'User email',
      valueGetter: (order: GridValueGetterParams<User, Order>) => {
        return order.row.userData.email;
      },
      width: 300,
    },
    {
      field: 'userPhone',
      headerName: 'User phone',
      valueGetter: (order: GridValueGetterParams<User, Order>) => {
        return order.row.userData.phone;
      },
      width: 300,
    },
    {
      field: 'restaurantData.name',
      headerName: 'Restaurant',
      valueGetter: (order: GridValueGetterParams<User, Order>) => {
        return order.row?.restaurantData?.name;
      },
      width: 300,
    },
    {
      field: 'totalPrice',
      headerName: 'Paid amount ($)',
      valueGetter: (price: GridValueGetterParams<number, Order>) => {
        return `$${price.value?.toString()}`;
      },
      width: 150,
    },
    {
      field: 'status',
      headerName: 'Payment status',
      width: 250,
      renderCell: ({ row }: { row: Order }) => {
        switch (row.status) {
          case 'PLACED':
            return <div className="paid">PLACED</div>;
          case 'PAID':
            return <div className="paid">PAID</div>;
          case 'PICKED UP':
            return <div className="picked-up">PICKED UP</div>;
          case 'REFUNDED':
            return <div className="refunded">REFUNDED</div>;
        }
      },
    },
    {
      field: '',
      headerName: 'Action',
      width: 250,
      sortable: false,
      renderCell: ({ row }: { row: Order }) => {
        switch (row.status) {
          case 'PAID':
            return (
              <div className="flex gap-2 items-center justify-center">
                <Button
                  onClick={() => updateOrder({ ...row, status: 'PICKED UP' })}>
                  Picked up
                </Button>
                <Eye
                  size={20}
                  color="rgb(96 165 250)"
                  className="cursor-pointer"
                  onClick={() => {
                    setSelectedOrder(row);
                    markOrderAsRead(row);
                  }}
                />
              </div>
            );

          case 'PLACED':
            return (
              <div className="flex gap-2 items-center justify-center">
                <Button
                  onClick={() => updateOrder({ ...row, status: 'PICKED UP' })}>
                  Picked up
                </Button>
                <Eye
                  size={20}
                  color="rgb(96 165 250)"
                  className="cursor-pointer"
                  onClick={() => {
                    setSelectedOrder(row);
                    markOrderAsRead(row);
                  }}
                />
              </div>
            );
          case 'PICKED UP':
            return (
              <div className="flex gap-2 items-center justify-center">
                <Button
                  onClick={() => updateOrder({ ...row, status: 'REFUNDED' })}>
                  Refund
                </Button>
                <Eye
                  size={20}
                  color="rgb(96 165 250)"
                  className="cursor-pointer"
                  onClick={() => {
                    setSelectedOrder(row);
                    markOrderAsRead(row);
                  }}
                />
              </div>
            );
          case 'REFUNDED':
            return (
              <div className="flex gap-2 items-center justify-center">
                <Eye
                  size={20}
                  color="rgb(96 165 250)"
                  className="cursor-pointer"
                  onClick={() => {
                    setSelectedOrder(row);
                    markOrderAsRead(row);
                  }}
                />
              </div>
            );
        }
      },
    },
  ];

  return (
    <>
      <OrderDetailsDialog
        open={!!selectedOrder}
        setOpen={open => setSelectedOrder(selected => (open ? selected : null))}
        order={selectedOrder as Order}
      />
      <StyledOrdersList>
        <h1 className="title">DETAILED ORDERS</h1>
        <div className="orders-container">
          <DataGrid
            rows={ordersData.data?.map(row => ({ ...row, id: row._id })) || []}
            columns={columns}
          />
        </div>
      </StyledOrdersList>
    </>
  );
}
