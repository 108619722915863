import React, { useState } from 'react';
import {
  Create,
  SimpleForm,
  TextField,
  TextInput,
  useDataProvider,
  useNotify,
} from 'react-admin';
import { StyledRestaurantCreate } from './StyledRestaurantCreate';
import AddressInput from '../../../shared/AddressInput/AddressInput';
import { FieldValues } from 'react-hook-form';
import { RESTAURANT_RESOURCE } from '../../../constants';
import { useNavigate } from 'react-router-dom';

export default function InviteRestaurants() {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const navigate = useNavigate();

  const [restaurantAddress, setRestaurantAddress] = useState<{
    lat: number;
    lng: number;
    zipCode?: string;
  }>();

  const submitForm = async (data: FieldValues) => {
    const requestBody = {
      ...data,
      lat: restaurantAddress?.lat,
      lng: restaurantAddress?.lng,
      zipCode: restaurantAddress?.zipCode,
    };

    dataProvider
      .create(RESTAURANT_RESOURCE, {
        data: requestBody,
      })
      .then(() => {
        navigate(`/${RESTAURANT_RESOURCE}`);
        notify('Restaurant updated successfully', { type: 'success' });
      })
      .catch(error => {
        try {
          if (error.message.includes('zipCode')) {
            notify('Invalid location. Location does not contain a zip code', {
              type: 'error',
            });
          }
        } catch (e) {
          notify('Could not update restaurant. Please try again', {
            type: 'error',
          });
        }
      });
  };

  return (
    <StyledRestaurantCreate>
      <h1 className="title">Add new restaurant</h1>
      <Create>
        <SimpleForm onSubmit={submitForm}>
          <div className="fields-container">
            <div className="column-1">
              <h2>Restaurant detasdsdils</h2>
              <label>Restaurant name</label>
              <TextInput source="name" label="Restaurant name..." />
              <label>Phone number</label>
              <TextInput source="phoneNumber" label="Phone number..." />

              <label>Address</label>
              <AddressInput
                source="address"
                label="Restaurant address"
                addressChangeCallback={setRestaurantAddress}
                isRequired={true}
              />
              <label>Sales Tax</label>
              <TextInput source="salesTax" label="Restaurant tax" />
            </div>
            <div className="column-2">
              <h2>Login information</h2>
              <label>Email</label>
              <TextInput source="email" label="Email..." />
              <label>Password</label>
              <TextInput source="password" label="Password..." />
            </div>
          </div>
        </SimpleForm>
      </Create>
    </StyledRestaurantCreate>
  );
}
