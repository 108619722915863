const globalTheme = {
  light: {
    green: "#609A27",
    orange: "#FFB23B",
    white: "#ffffff",
    blue: "#066164",
    lightBlue: "#0c8f94",
    dark: "#232128",
    lightGreen: "#44DB42",
    brown: "#4B2B13",
    background_500: "#FFB23B",
    background_600: "#F9FAFB",
  },
};

export const getThemeColor = (color: string) => {
  type ThemeColorType = keyof typeof globalTheme.light;
  return globalTheme.light[color as ThemeColorType];
};

export default globalTheme;
