import { apiSlice } from '../api/api';
import { serializeQueryString } from '../helpers/query-string';

type DateArguments = { startDate: string; endDate: string };

export const analyticsSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getUsers: builder.query({
      query: ({ startDate, endDate }: DateArguments) =>
        `/api/analytics/users?${serializeQueryString({
          startDate,
          endDate,
        })}`,
      transformResponse: (response: { date: string; count: number }[]) =>
        response.map(data => ({
          ...data,
          date: new Date(data.date).toLocaleDateString(),
        })),
    }),
    getOrders: builder.query({
      query: ({ startDate, endDate }: DateArguments) =>
        `/api/analytics/orders?${serializeQueryString({
          startDate,
          endDate,
        })}`,
      transformResponse: (response: { _id: string; count: number }[]) =>
        response.map(data => ({
          date: new Date(data._id).toLocaleDateString(),
          count: data.count,
        })),
    }),
    getMoney: builder.query({
      query: ({ startDate, endDate }: DateArguments) =>
        `/api/analytics/money?${serializeQueryString({ startDate, endDate })}`,
      transformResponse: (response: { _id: string; money: number }[]) =>
        response.map(data => ({
          date: new Date(data._id).toLocaleDateString(),
          money: data.money,
        })),
    }),
  }),
});

export const { useGetUsersQuery, useGetOrdersQuery, useGetMoneyQuery } =
  analyticsSlice;
export default analyticsSlice.reducer;
