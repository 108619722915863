import React from 'react';
import { useGlobalContext } from '../context/global.context';
import { List, Datagrid, TextField, EmailField, TextInput } from 'react-admin';

const RestaurantFilters = [
  <TextInput label="Search by name" source="name" alwaysOn />,
  <TextInput label="Search by email" source="email" />,
  <TextInput label="Search by phone number" source="phoneNumber" />,
  <TextInput label="Latitude" source="lat" />,
  <TextInput label="Longitude" source="lng" />,
];

const RestaurantsList = () => {
  const { dispatch } = useGlobalContext();

  React.useEffect(() => {
    dispatch({ type: 'CHANGE_TITLE', payload: 'restaurants' });
  }, []);

  return (
    <List filters={RestaurantFilters}>
      <Datagrid
        rowClick="edit"
        rowStyle={() => ({
          height: 63,
        })}>
        <TextField source="name" />
        <EmailField source="email" />
        <TextField source="address" />
        <TextField source="phoneNumber" />
        <TextField source="totalRevenue" />
      </Datagrid>
    </List>
  );
};

export default RestaurantsList;
