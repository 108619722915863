import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  Button,
  EditButton,
  RecordContextProvider,
  DatagridBody,
} from 'react-admin';
import { TableCell, TableRow, Checkbox, TableHead } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Bag } from '../types/bags';
import { useGlobalContext } from '../context/global.context';

interface DataGridRow {
  children: React.ReactElement[];
  record: Bag;
  id: string;
  onToggleItem: Function;
  selected: boolean;
  selectable: boolean;
}

const BagsFilters = [
  <TextInput label="Search by name" source="name" alwaysOn />,
];

const DatagridHeader = ({ children }: { children: React.ReactElement[] }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell></TableCell>{' '}
        {children.map(child => (
          <TableCell key={child.props.source}>
            {child.props.source
              ? `${child.props.source
                  .slice(0, 1)
                  .toUpperCase()}${child.props.source.slice(1)}`
              : 'Action'}
          </TableCell>
        ))}
        <TableCell key={'view orders'}>Action</TableCell>
      </TableRow>
    </TableHead>
  );
};

const MyDatagridRow = (props: DataGridRow) => {
  const navigate = useNavigate();
  const { record, id, onToggleItem, children, selected } = props;
  return (
    <RecordContextProvider value={record}>
      <TableRow>
        <TableCell padding="none">
          <Checkbox
            disabled={false}
            checked={selected}
            onClick={event => onToggleItem(id, event)}
          />
        </TableCell>
        {children.map((field: React.ReactElement) => (
          <TableCell key={`${id}-${field?.props?.source}`}>{field}</TableCell>
        ))}
        <TableCell key={`${id}-view-orders`}>
          <Button onClick={() => navigate(`/orders/${record.id}`)}>
            <p style={{ fontSize: 12 }}>View Orders</p>
          </Button>
        </TableCell>
      </TableRow>
    </RecordContextProvider>
  );
};

const MyDatagridBody = (props: any) => (
  <DatagridBody {...props} row={<MyDatagridRow {...props} />} />
);

const MyDatagrid = (props: { children: React.ReactElement[] }) => (
  <Datagrid
    {...props}
    body={<MyDatagridBody {...props} />}
    header={<DatagridHeader children={props.children} />}
  />
);

const BagsList = () => {
  const { dispatch } = useGlobalContext();

  React.useEffect(() => {
    dispatch({ type: 'CHANGE_TITLE', payload: 'bags' });
  }, []);

  return (
    <List filters={BagsFilters}>
      <MyDatagrid>
        <TextField source="name" />
        <TextField source="price" />
        <TextField source="restaurant" />
        <TextField source="stock" />
        <EditButton />
      </MyDatagrid>
    </List>
  );
};

export default BagsList;
