export const LOGIN_PATH: string = process.env.REACT_APP_API_URL + '/auth/login';
export const FORGOT_PASSWORD_PATH: string =
  process.env.REACT_APP_API_URL + '/auth/forgotPassword';
export const RESET_PASSWORD_PATH: string =
  process.env.REACT_APP_API_URL + '/auth/resetPassword';
export const REDIRECT_URL: string = window.location.host + '/resetPassword/';
export const ORDERS_PATH: string = '/api/orders/';
export const ORDERS_URL: string = process.env.REACT_APP_API_URL + '/api/orders';
export const API_PATH: string =
  process.env.REACT_APP_API_URL || 'http://localhost:8080/';

//User types
export const ADMIN = 'ADMIN';
export const REGULAR = 'REGULAR';
export const RESTAURANT = 'RESTAURANT';

//Local Storage
export const USER_KEY = 'user';
export const PERMISSIONS_KEY = 'permissions';

//React Admin resources
export const RESTAURANT_RESOURCE = 'restaurant';
export const BAGS_RESOURCE = 'bags';
export const SUPPORT_RESOURCE = 'supportChat';
export const USERS_RESOURCE = 'users';
export const ORDERS_RESOURCE = 'orders';
export const DASHBOARD_RESOURCE = 'dashboard';

//Query params names
export const SORT_FIELD = 'sortField';
export const SORT_ORDER = 'sortOrder';
export const PAGE = 'page';
export const PER_PAGE = 'perPage';
