import styled from "styled-components";
import { getThemeColor } from "./global-theme";

const StyledApp = styled.div`
  background-color: ${getThemeColor("dark")};
  .RaLayout-contentWithSidebar {
    background-color: ${getThemeColor("white")};
  }
  header {
    background-color: ${getThemeColor("white")};
    color: black;
    box-shadow: 0px 32px 85px -32px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(126, 126, 126, 0.1);
  }
  #react-admin-title {
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1.4px;
    font-family: "Inter";
  }
  #main-content {
    background-color: #f4f4f4;
  }
`;

export default StyledApp;
