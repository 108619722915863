import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { store } from './redux/store';
import { Provider } from 'react-redux';
import { Admin, Resource } from 'react-admin';
import { authProvider } from './providers/auth.provider';
import dataProvider from './providers/data.provider';
import RestaurantsList from './views/Restaurants.view';
import RestaurantsEdit from './components/Restaurants/edit/RestaurantsEdit';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ForgotPassword from './components/Authentication/ForgotPassword';
import ResetPassword from './components/Authentication/ResetPassword';
import {
  BAGS_RESOURCE,
  DASHBOARD_RESOURCE,
  ORDERS_RESOURCE,
  RESTAURANT_RESOURCE,
  USERS_RESOURCE,
} from './constants';
import InviteRestaurants from './components/Restaurants/create/InviteRestaurants';
import BagsList from './views/Bags.view';
import BagsEdit from './components/Bags/edit/BagsEdit';
import BagsCreate from './components/Bags/create/BagsCreate';
import UsersList from './views/Users.view';
import UserEdit from './components/UsersEdit';
import OrdersList from './components/Orders/OrdersList';

import { defaultTheme } from 'react-admin';

// import SupportChat from './components/SupportChat/SupportChat';
import StyledApp from './StyledApp';
import AdminLayout from './components/ReactAdminOverrides/Layout';
import GlobalContextProvider from './context/GlobalContext';
import CheckoutView from './views/Checkout.view';
import Dashboard from './components/Dashboard/Dashboard';

const theme = {
  ...defaultTheme,
  components: {
    ...defaultTheme.components,
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '@font-face': [
            {
              fontFamily: 'Inter',
              src: `url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap") format('opentype')`,
            },
          ],
        },
      },
    },
  },
};

const AdminRoute = () => {
  return (
    <GlobalContextProvider>
      <Admin
        authProvider={authProvider}
        dataProvider={dataProvider}
        theme={theme}
        layout={AdminLayout}>
        {permissions => {
          return (
            <>
              <Resource
                name={DASHBOARD_RESOURCE}
                options={{
                  label: 'Dashboard',
                }}
                list={<Dashboard />}
              />
              <Resource
                name={RESTAURANT_RESOURCE}
                list={RestaurantsList}
                edit={RestaurantsEdit}
                create={permissions === 'ADMIN' ? InviteRestaurants : undefined}
              />
              <Resource
                name={BAGS_RESOURCE}
                list={BagsList}
                edit={BagsEdit}
                create={permissions === 'RESTAURANT' ? BagsCreate : undefined}
              />
              <Resource name={ORDERS_RESOURCE} list={<OrdersList />} />
              {/* <Resource name={ORDERS_RESOURCE} list={OrdersList} /> */}
              {/* {permissions === 'ADMIN' && (
                <Resource
                  name="supportChat"
                  options={{ label: 'Support' }}
                  list={<SupportChat />}
                />
              )} */}
              {permissions === 'ADMIN' && (
                <Resource
                  name={USERS_RESOURCE}
                  list={UsersList}
                  edit={UserEdit}
                />
              )}
              {/* <CustomRoutes>
                <Route path="/orders/:bagId" element={<OrdersList />} />
              </CustomRoutes> */}
            </>
          );
        }}
      </Admin>
    </GlobalContextProvider>
  );
};

const router = createBrowserRouter([
  {
    path: '/*',
    element: <AdminRoute />,
  },
  {
    path: '/forgotPassword',
    element: <ForgotPassword />,
  },
  {
    path: '/resetPassword/:token',
    element: <ResetPassword />,
  },
  {
    path: '/mobileCheckout',
    element: <CheckoutView />,
  },
]);

function App() {
  return (
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StyledApp>
          <RouterProvider router={router} />
        </StyledApp>
      </LocalizationProvider>
    </Provider>
  );
}

export default App;
