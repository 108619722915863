import React, { useState } from 'react';
import { Grid, Paper, Avatar, TextField, Button } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import UserService from '../../services/user.service';
import { APIResponse } from '../../types/api-response';

export default function ForgotPassword() {
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');

  const paperStyle = {
    padding: 20,
    height: '94vh',
    width: 360,
    margin: '20px auto',
    backgroundColor: 'white',
  };

  const avatarStyle = { backgroundColor: '#44b762' };

  const btnstyle = {
    margin: '8px 0',
    backgroundColor: '#44b762',
    borderRadius: 0,
    height: 56,
    fontWeight: 800,
    shadowOffset: 0,
  };

  const submit = async () => {
    const response: APIResponse = await UserService.sendForgotPasswordEmail(
      email.toLowerCase(),
    );

    if (response.error) {
      setError(response.error);
      setSuccessMessage('');
    } else if (response.response) {
      setSuccessMessage(response.response);
      setError('');
    } else {
      setError('Could not send email');
      setSuccessMessage('');
    }
  };

  return (
    <Grid style={{ backgroundColor: 'white' }}>
      <Paper elevation={0} style={paperStyle}>
        <Grid>
          <Avatar style={avatarStyle}>
            <LockOutlinedIcon />
          </Avatar>
          <h2>Forgot Password</h2>
        </Grid>
        <TextField
          label="Email"
          placeholder="Enter email"
          variant="filled"
          fullWidth
          required
          style={{
            margin: '10px 0',
            backgroundColor: '#ecf1f5',
            border: 0,
            fontWeight: 800,
          }}
          onChange={event => setEmail(event.target.value.toLowerCase())}
          inputProps={{ autoCapitalize: 'none' }}
        />

        <Button
          disableElevation
          type="submit"
          color="primary"
          variant="contained"
          style={btnstyle}
          fullWidth
          onClick={submit}>
          Send reset email
        </Button>

        {error && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {error}
          </Alert>
        )}

        {successMessage && (
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            {successMessage}
          </Alert>
        )}
      </Paper>
    </Grid>
  );
}
