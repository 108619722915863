import axios from 'axios';
import {
  CreateParams,
  DataProvider,
  DeleteParams,
  GetListParams,
  GetOneParams,
  GetOneResult,
  LegacyDataProvider,
  RaRecord,
  UpdateParams,
} from 'react-admin';
import UserService from '../services/user.service';
import { RestaurantResponse } from '../types/api-response';
import {
  PAGE,
  PER_PAGE,
  RESTAURANT_RESOURCE,
  SORT_FIELD,
  SORT_ORDER,
} from '../constants';
import { serializeQueryString } from '../helpers/query-string';

const { REACT_APP_API_URL } = process.env;

const dataProvider: DataProvider<string> | LegacyDataProvider = {
  getList: async (resource: string, params: GetListParams) => {
    const url =
      `${REACT_APP_API_URL}/api/${resource}?${SORT_FIELD}=${params.sort.field}&${SORT_ORDER}=${params.sort.order}&${PAGE}=${params.pagination.page}&${PER_PAGE}=${params.pagination.perPage}` +
      (Object.keys(params.filter).length > 0
        ? `&${serializeQueryString(params.filter)}`
        : '');

    return await axios
      .get(url, {
        headers: {
          Authorization: UserService.getUser(),
        },
      })
      .then(response => {
        const data = response.data.data.map((entry: any, index: number) => ({
          ...entry,
          id: entry._id,
        }));
        return {
          data,
          total: response.data.total,
        };
      });
  },

  getOne: async <RestaurantResponse extends RaRecord>(
    resource: string,
    params: GetOneParams,
  ): Promise<GetOneResult<RestaurantResponse>> => {
    const url = `${REACT_APP_API_URL}/api/${resource}/${params.id}`;
    const response = await axios
      .get(url, {
        headers: {
          Authorization: UserService.getUser(),
        },
      })
      .then(response => {
        const restaurant: RestaurantResponse = {
          ...response.data,
          id: response.data._id,
        };
        return {
          data: restaurant,
        };
      });
    return response;
  },

  getMany: (resource: string, params: any) => {
    return Promise.reject();
    //     const query = {
    //       filter: JSON.stringify({ ids: params.ids }),
    //     };
    //     const url = `${apiUrl}/${resource}?${stringify(query)}`;
    //     return httpClient(url).then(({ json }) => ({ data: json }));
  },

  getManyReference: (resource: any, params: any) => {
    return Promise.reject();
    //     const { page, perPage } = params.pagination;
    //     const { field, order } = params.sort;
    //     const query = {
    //       sort: JSON.stringify([field, order]),
    //       range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
    //       filter: JSON.stringify({
    //         ...params.filter,
    //         [params.target]: params.id,
    //       }),
    //     };
    //     const url = `${apiUrl}/${resource}?${stringify(query)}`;
    //     return httpClient(url).then(({ headers, json }) => ({
    //       data: json,
    //       total: parseInt(headers.get("content-range").split("/").pop(), 10),
    //     }));
  },

  //@ts-ignore
  create: async (resource: string, params: CreateParams) => {
    const url = `${REACT_APP_API_URL}/api/${resource}`;
    const response = await axios
      .post(url, params.data, {
        headers: {
          Authorization: UserService.getUser(),
        },
      })
      .then(response => {
        const restaurant: RestaurantResponse = {
          ...response.data,
          id: response.data._id,
        };
        return {
          data: restaurant,
        };
      })
      .catch(error => {
        console.error(error);
        if (resource === RESTAURANT_RESOURCE) {
          throw new Error(JSON.stringify(error.response.data));
        }
        return error;
      });

    return response;
  },

  update: async (resource: string, params: UpdateParams) => {
    const url = `${REACT_APP_API_URL}/api/${resource}/${params.id}`;

    return axios
      .put(url, params.data, {
        headers: {
          Authorization: UserService.getUser(),
        },
      })
      .then(response => {
        return { data: response.data };
      })
      .catch(error => {
        console.error(error);
        return error;
      });
  },

  updateMany: (resource: string, params: any) => {
    return Promise.reject();
    //     const query = {
    //       filter: JSON.stringify({ id: params.ids }),
    //     };
    //     return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
    //       method: "PUT",
    //       body: JSON.stringify(params.data),
    //     }).then(({ json }) => ({ data: json }));
  },

  delete: (resource: string, params: DeleteParams) => {
    return axios
      .delete(`${REACT_APP_API_URL}/api/${resource}/${params.id}`, {
        headers: {
          Authorization: UserService.getUser(),
        },
      })
      .then(response => {
        return response.data;
      });
  },
  //     httpClient(`${apiUrl}/${resource}/${params.id}`, {
  //       method: "DELETE",
  //     }).then(({ json }) => ({ data: json })),

  deleteMany: (resource: string, params: any) => {
    return Promise.reject();
    //     const query = {
    //       filter: JSON.stringify({ id: params.ids }),
    //     };
    //     return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
    //       method: "DELETE",
    //       body: JSON.stringify(params.data),
    //     }).then(({ json }) => ({ data: json }));
  },
};

export default dataProvider;
