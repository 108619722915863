import styled from 'styled-components';

export const StyledAddressInput = styled.div`
  margin-bottom: 1.75rem;
  width: 100%;

  input {
    width: 100%;
  }
`;
