import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import UserService from '../services/user.service';

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers: Headers, api: any) => {
      const token = UserService.getUser();
      if (token) {
        headers.set('authorization', `${token}`);
      }
      return headers;
    },
  }),
  endpoints: _builder => ({}),
});
