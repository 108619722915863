import React, { useEffect } from 'react';
import { AppBar } from 'react-admin';
import { Typography } from '@mui/material';
import { StyledAppBar } from './StyledAppBar';
import { useGlobalContext } from '../../../context/global.context';

const EcoAppBar = () => {
  const { state } = useGlobalContext('pageTitle');

  return (
    <StyledAppBar>
      <AppBar
        sx={{
          '& .RaAppBar-title': {
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          },
        }}
        // title={'123123'}
        // placeholder="123123"
      >
        <Typography className="title">{state.toString()}</Typography>
      </AppBar>
    </StyledAppBar>
  );
};

export default EcoAppBar;
