import styled from 'styled-components';

export const StyledDashboard = styled.div`
  height: 100%;
  padding: 20px;
  width: 100%;

  .container {
    width: 100%;
    border-radius: 10px;
    -webkit-box-shadow: 9px 3px 35px -5px rgba(163, 163, 163, 1);
    -moz-box-shadow: 9px 3px 35px -5px rgba(163, 163, 163, 1);
    box-shadow: 9px 3px 35px -5px rgba(163, 163, 163, 1);
  }

  .title {
    margin: 0;
  }

  .card {
    padding: 10px;
    padding-right: 10%;
    margin: 0 10px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;

    .pickers {
      display: flex;
      gap: 5px;
    }
  }
`;
