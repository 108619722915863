import styled from "styled-components";

export const StyledRestaurantCreate = styled.div`
  padding: 20px;

  .title {
    margin: 0;
  }

  .MuiPaper-root {
    border-radius: 10px;
  }

  .fields-container {
    display: flex;
    gap: 20px;
    width: 100%;

    .column-1 {
      flex: 1;
      width: 100%;
      border: 1px solid #dadada;
      padding: 15px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
    }

    .column-2 {
      flex: 1;
      width: 100%;
      border: 1px solid #dadada;
      padding: 15px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
    }
  }
`;
