import {
  FORGOT_PASSWORD_PATH,
  REDIRECT_URL,
  RESET_PASSWORD_PATH,
  USER_KEY,
} from "../constants";
import axios, { AxiosError } from "axios";
import { APIResponse } from "../types/api-response";

interface User {
  token: string;
  userType: string;
  name: string;
  id: string;
}

const UserService = {
  getUser: () => {
    const userString: string | null = localStorage.getItem(USER_KEY);
    if (userString) {
      const user: User = JSON.parse(userString);
      return user.token;
    }
  },
  getUserData: () => {
    const userString: string | null = localStorage.getItem(USER_KEY);
    if (userString) {
      const user: User = JSON.parse(userString);
      return user;
    }
  },
  sendForgotPasswordEmail: async (email: string): Promise<APIResponse> => {
    try {
      const response = await axios
        .post(FORGOT_PASSWORD_PATH, {
          email,
          redirectUrl: REDIRECT_URL,
        })
        .catch((error: AxiosError) => error.response);
      if (!response) {
        return { error: "Could not send email" };
      }
      return response.data;
    } catch (error) {
      return { error: "Could not send email" };
    }
  },
  resetPassword: async (
    token: string,
    newPassword: string
  ): Promise<APIResponse> => {
    try {
      const response = await axios
        .post(RESET_PASSWORD_PATH, {
          token,
          newPassword,
        })
        .catch((error: AxiosError) => error.response);
      if (!response) {
        return { error: "Could not reset password" };
      }
      return response.data;
    } catch (error) {
      return { error: "Could not reset password" };
    }
  },
};

export default UserService;
