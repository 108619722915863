import React, { useState } from 'react';
import {
  Grid,
  Paper,
  Avatar,
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import UserService from '../../services/user.service';
import { APIResponse } from '../../types/api-response';
import { useParams } from 'react-router-dom';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';

export default function ResetPassword() {
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] =
    useState<boolean>(false);

  const { token } = useParams();

  const paperStyle = {
    padding: 20,
    height: '70vh',
    width: 360,
    margin: 'auto',
    overflow: 'hidden',
    backgroundColor: 'white',
  };

  const avatarStyle = { backgroundColor: '#44b762' };
  const btnstyle = {
    margin: '8px 0',
    backgroundColor: '#44b762',
    borderRadius: 0,
    height: 56,
    fontWeight: 800,
    shadowOffset: 0,
  };

  const textFieldStyle = {
    margin: '10px 0',
    backgroundColor: '#ecf1f5',
    border: 0,
    fontWeight: 800,
  };

  const submit = async () => {
    if (!token) {
      return;
    }
    if (confirmPassword !== password || password.length === 0) {
      setError('Passwords do not match');
      setSuccessMessage('');
      return;
    }
    const response: APIResponse = await UserService.resetPassword(
      token,
      password,
    );

    if (response.error) {
      setError(response.error);
      setSuccessMessage('');
    } else if (response.response) {
      setSuccessMessage(response.response);
      setError('');
    } else {
      setError('Could not send email');
      setSuccessMessage('');
    }
  };

  return (
    <Grid style={{ height: '100vh', paddingTop: 20 }}>
      <Paper elevation={0} style={paperStyle}>
        <Grid>
          <Avatar style={avatarStyle}>
            <LockOutlinedIcon />
          </Avatar>
          <h2>Reset Password</h2>
        </Grid>

        <TextField
          label="New Password"
          placeholder="Enter new password"
          variant="filled"
          fullWidth
          required
          style={textFieldStyle}
          onChange={event => setPassword(event.target.value)}
          type={passwordVisible ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setPasswordVisible(!passwordVisible)}>
                  {!passwordVisible ? (
                    <VisibilityOutlined />
                  ) : (
                    <VisibilityOffOutlined />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextField
          label="Confirm new password"
          placeholder="Confirm password"
          variant="filled"
          fullWidth
          required
          style={textFieldStyle}
          onChange={event => setConfirmPassword(event.target.value)}
          type={confirmPasswordVisible ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() =>
                    setConfirmPasswordVisible(!confirmPasswordVisible)
                  }>
                  {!confirmPasswordVisible ? (
                    <VisibilityOutlined />
                  ) : (
                    <VisibilityOffOutlined />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Button
          disableElevation
          type="submit"
          color="primary"
          variant="contained"
          style={btnstyle}
          fullWidth
          onClick={submit}>
          Reset your password
        </Button>

        {error && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {error}
          </Alert>
        )}

        {successMessage && (
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            {successMessage}
          </Alert>
        )}
      </Paper>
    </Grid>
  );
}
