import { List, Datagrid, TextField, TextInput } from 'react-admin';
import { useGlobalContext } from '../context/global.context';
import React from 'react';

const UserFilters = [
  <TextInput label="Search by name" source="name" alwaysOn />,
  <TextInput label="Search by email" source="email" />,
  <TextInput label="Search by city" source="city" />,
  <TextInput label="Search by state" source="state" />,
  <TextInput label="Search by voucher amount" source="voucherAmount" />,
];

const UsersList = () => {
  const { dispatch } = useGlobalContext();

  React.useEffect(() => {
    dispatch({ type: 'CHANGE_TITLE', payload: 'users' });
  }, []);

  return (
    <List filters={UserFilters}>
      <Datagrid
        rowClick="edit"
        rowStyle={() => ({
          height: 63,
        })}>
        <TextField source="email" />
        <TextField source="name" />
        <TextField source="city" />
        <TextField source="state" />
        <TextField source="userType" />
        <TextField source="voucherAmount" />
      </Datagrid>
    </List>
  );
};

export default UsersList;
