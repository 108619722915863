import { Edit, SimpleForm, TextInput } from 'react-admin';

const UserEdit = () => {
  return (
    <Edit>
      <SimpleForm>
        <h2>General information</h2>
        <TextInput source="id" disabled />
        <TextInput source="name" disabled />
        <TextInput source="city" disabled />
        <TextInput source="state" disabled />
        <TextInput source="voucherAmount" label="Voucher amount ($)" />
      </SimpleForm>
    </Edit>
  );
};

export default UserEdit;
