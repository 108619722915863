export function serializeQueryString(obj: object) {
  let queryString = [];
  type ObjectKeys = keyof typeof obj;
  for (const key in obj) {
    if (Object.hasOwn(obj, key)) {
      queryString.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(
          obj[key as ObjectKeys]
        )}`
      );
    }
  }

  return queryString.join("&");
}
