import axios from 'axios';
import {
  ADMIN,
  LOGIN_PATH,
  PERMISSIONS_KEY,
  RESTAURANT,
  USER_KEY,
} from '../constants';
import { LoginResponse } from '../types/api-response';
import jwtDecode from 'jwt-decode';
import { User } from '../types/user';

export const authProvider = {
  login: async ({
    username,
    password,
  }: {
    username: string;
    password: string;
  }) => {
    try {
      const response = await axios.post(LOGIN_PATH, {
        email: username,
        password,
      });

      const user: LoginResponse = response.data;
      if (user.type === ADMIN || user.type === RESTAURANT) {
        localStorage.setItem(USER_KEY, JSON.stringify(user));
        localStorage.setItem(PERMISSIONS_KEY, user.type);
        return Promise.resolve();
      }

      return Promise.reject();
    } catch (error) {
      return Promise.reject();
    }
  },

  logout: () => {
    localStorage.removeItem(USER_KEY);
    localStorage.removeItem(PERMISSIONS_KEY);
    return Promise.resolve();
  },

  checkError: (status: { status: number }) => {
    if (
      status?.status === 401 ||
      status?.status === 403 ||
      status?.status === 400
    ) {
      localStorage.removeItem(USER_KEY);
      localStorage.removeItem(PERMISSIONS_KEY);
      return Promise.reject();
    }
    return Promise.resolve();
  },

  checkAuth: () => {
    const user = localStorage.getItem(USER_KEY);
    return user ? Promise.resolve() : Promise.reject();
  },

  getIdentity: () => {
    try {
      const userString: string | null = localStorage.getItem(USER_KEY);
      if (userString) {
        const user: LoginResponse = JSON.parse(userString);
        const decodedUser: User = jwtDecode(user.token);

        return Promise.resolve({
          id: decodedUser._id,
          fullName: decodedUser.name,
          avatar: '',
        });
      }
      return Promise.reject();
    } catch (error) {
      return Promise.reject();
    }
  },

  getPermissions: (): Promise<'ADMIN' | 'RESTAURANT' | 'REGULAR'> => {
    const permissions = localStorage.getItem(PERMISSIONS_KEY);
    if (
      !permissions ||
      (permissions !== 'ADMIN' &&
        permissions !== 'RESTAURANT' &&
        permissions !== 'REGULAR')
    ) {
      return Promise.reject();
    }
    return Promise.resolve(permissions);
  },
};
